<template>
  <div style="padding: 50px;width: 100%;height: 100%;display:flex;justify-content: center;line-height: 38px">
    <div style="width: 68%">
      <h1>隱私政策</h1>
      <div>
        <span style="margin-left: 33px"/>歡迎使用我們的台胞尋根智慧平台。我們非常重視您的隱私和數據安全。在您使用我們的服務時，我們會收集和使用您的個人信息。請仔細閱讀本隱私政策，以了解我們如何收集、使用和保護您的個人信息。
      </div>

      <h2>一、我們收集的信息</h2>
      <p>在您使用我們的台胞尋根智慧平台時，我們可能會收集以下信息：</p>
      <ol>
        <li><strong>手機號碼</strong>：我們會收集您的手機號碼，用於後續的回覆和消息通知。</li>
        <li><strong>留言內容</strong>：當您在我們的平台上進行留言評價時，我們會收集您的留言內容，以便我們的管理人員處理您的疑問並提供更好的服務。</li>
      </ol>

      <h2>二、我們如何使用您的信息</h2>
      <p>我們收集的信息將用於以下目的：</p>
      <ul>
        <li><strong>消息通知</strong>：當您有疑問或留言時，我們的管理人員會進行處理，並通過短信或帳號消息通知欄向您回覆。</li>
        <li><strong>改進服務</strong>：您的反饋和評價將幫助我們改進服務質量和用戶體驗。</li>
      </ul>
      <h2>三、信息保護</h2>
      <p>我們致力於保護您的個人信息安全，防止未經授權的訪問、披露、修改或毀壞。我們採取了適當的技術和組織措施來保護您的信息安全，包括但不限於：</p>
      <ul>
        <li><strong>數據加密</strong>：我們使用加密技術來保護數據傳輸的安全性。</li>
        <li><strong>訪問控制</strong>：僅授權的員工和管理人員才能訪問您的個人信息。</li>
        <li><strong>安全審計</strong>：定期進行安全審計，確保我們的系統和流程符合隱私保護要求。</li>
      </ul>
      <h2>四、信息分享</h2>
      <p>我們不會向第三方出售、交易或轉讓您的個人信息，除非在以下情況下：</p>
      <ul>
        <li><strong>法律要求</strong>：在法律要求或政府機構要求時，我們可能會披露您的個人信息。</li>
        <li><strong>業務轉讓</strong>：在業務合併、收購或資產出售的情況下，您的個人信息可能會被轉移給新的實體。</li>
      </ul>
      <h2>五、您的權利</h2>
      <p>您對您的個人信息擁有以下權利：</p>
      <ul>
        <li><strong>訪問和更新</strong>：您有權訪問和更新您的個人信息。</li>
        <li><strong>刪除</strong>：在某些情況下，您可以請求刪除您的個人信息。</li>
        <li><strong>撤回同意</strong>：您可以隨時撤回對我們收集和使用您的個人信息的同意。</li>
      </ul>
      <div class="contact-info">
        <h2>六、聯繫我們</h2>
        <p>如果您對本隱私政策有任何疑問或需要進一步信息，請通過以下方式聯繫我們：</p>
        <p><strong>電子郵件</strong>：xxxxx@xx.com</p>
        <p><strong>電話</strong>：123-456-7890</p>
        <p><strong>地址</strong>：福州市軟件園C區創客谷409</p>
      </div>
      <h2>七、隱私政策的變更</h2>
      <div style="padding-bottom: 50px">
        <ul>
          <li>我們可能會不時更新本隱私政策。任何更新將發布在本頁面上，並在生效前通過適當的方式通知您。請定期查看本隱私政策，以了解我們的最新信息保護措施。</li>
          <li><strong>發布日期</strong>：2024年5月30日</li>
          <li>感謝您使用我們的服務！我們致力於保護您的隱私，並提供最優質的AI問答服務。</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index"
  }
</script>

<style scoped>
  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
  }
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
  }
  h2 {
    margin-top: 20px;
    font-size: 18px;
  }
  p {
    margin-bottom: 10px;
  }
  .contact-info {
  }
  .contact-info p {
    margin-bottom: 5px;
  }
</style>